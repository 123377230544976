<template>
  <div class="content-wrapper">
    <div class="d-flex align-items-center ml-4">
      <i
        class="nav-icon fa-2x fas fa-exclamation-circle font-color-custom mt-2"
      ></i>
      <h2 class="ml-2 mt-3">Novidades</h2>
    </div>
    <div class="container">
      <div class="row">
        <div class="col d-flex justify-content-between ml-4">
          <div class="d-flex align-items-center">
            <h5>Total de Novidade:</h5>
            <h5 class="ml-1">
              <strong>{{ totalNews }}</strong>
            </h5>
          </div>
          <div class="d-flex align-items-center">
            <router-link to="/admin/news/create" class="nav-link">
              <button class="createNews">
                Adicionar
              </button>
            </router-link>
            <!-- <input
              type="text"
              class="form-control seachNews"
              placeholder="Pesquisar"
              @keyup="getFilterNews()"
              title="Pesquisar Novidades"
              name="table_search"
            />
            <button type="button" class="btn btn-sm btn-search">
              <i class="fas fa-search"></i>
            </button> -->
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 mt-3">
          <div class="card">
            <div
              class="card-body table-responsive p-0"
              style="max-height: 440px;"
            >
              <table class="table table-hover table-head-fixed">
                <thead>
                  <tr>
                    <th>Título</th>
                    <th>Criado em</th>
                    <th>Autor</th>
                    <th>Visualizações</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="data in datas" :key="data.id">
                    <td>{{ data.title }}</td>
                    <td>{{ formatBrDate(data.createdAt) }}</td>
                    <td>{{ data.author.name }}</td>
                    <td>{{ data.viewsCount }}</td>
                    <td>
                      <router-link
                        tag="button"
                        :to="{ name: 'news_edit', params: { id: data.id } }"
                        :disabled="data.publishedAt"
                        class="btn btn-outline-primary btn-sm mr-1"
                        title="Editar"
                      >
                        <i class="fa fa-edit"></i>
                      </router-link>
                      <button
                        @click="deleteNews(data)"
                        title="Excluir"
                        :disabled="data.publishedAt"
                        class="btn btn-outline-danger btn-sm mr-1"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                      <button
                        @click="publishNews(data)"
                        title="Publicar"
                        :disabled="data.publishedAt"
                        class="btn btn-outline-primary btn-sm mr-2"
                      >
                        <i class="fa fa-share"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <button
              type="button"
              class="btn btn-outline-primary mr-1"
              @click="previousPage"
            >
              Anterior
            </button>
            <button
              type="button"
              class="btn btn-outline-primary ml-1"
              @click="nextPage"
            >
              Próximo
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      datas: [],
      proposalsSelected: {},
      totalNews: "",
      createdAt: "2023-10-16T10:30:00",
    };
  },
  mounted() {
    this.getData(1);
    this.pagination = 1;
  },

  methods: {
    formatBrDate(dateString) {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        return "Data inválida";
      }
      return date.toLocaleString("pt-BR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    },

    nextPage() {
      this.pagination++;
      const newValuePagination = this.pagination;
      this.getData(newValuePagination);
    },
    previousPage() {
      this.pagination--;
      const newValuePagination = this.pagination;
      this.getData(newValuePagination);
    },

    //métodos http:
    async getData(pagination) {
      try {
        const vuexData = JSON.parse(localStorage.getItem("vuex"));
        const token = vuexData.tokenJWT;
        const url = `${process.env.VUE_APP_URL_UPDATES_API}/news?sortBy=createdAt&sort=asc&limit=10&page=${pagination}`;
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(url, config);

        this.totalNews = response.data.total;
        this.datas = response.data.list.map((element) => element);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    publishNews(data) {
      const url = `${process.env.VUE_APP_URL_UPDATES_API}/news/${data.id}/publish`;
      if (confirm(`Você quer publicar a novidade: ${data.title}?`)) {
        axios
          .patch(url, null)
          .then((response) => {
            this.$toasted.global.defaultSuccess({
              msg: "Novidade publicada com sucesso!",
            });
          })
          .catch((error) => {
            this.$toasted.global.defaultError({
              msg: "Erro ao publicar o novidade!",
            });
            console.error("Error read!!", error);
          });
      }
    },

    deleteNews(id) {
      if (confirm("Tem certeza de que deseja excluir este item?")) {
        axios
          .delete(`${process.env.VUE_APP_URL_UPDATES_API}/news/${id.id}`)
          .then((response) => {
            this.datas = this.datas.filter((data) => data.id !== id);
            this.$toasted.global.defaultSuccess({
              msg: "Novidade deletada com sucesso!",
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            console.error("Error delete:", error);
            this.$toasted.global.defaultError({
              msg: "Erro ao excluir o item:",
            });
          });
      }
    },
  },
};
</script>

<style>
.createNews {
  background-color: #009acc;
  border: none;
  width: 150px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 20px;
}

.button-create {
  background-color: #009acc;
  border-radius: 20px;
}

.seachNews {
  width: 240px;
  height: 35px;
  border-radius: 20px;
}
</style>
